import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Gilroy, Arial, sans-serif',
    // Define other typography settings as needed
  },
  // Other theme settings
});

export default theme;
