import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { SideNavBar } from "../side-nav-bar/SideNavBar";
import { useEffect, useState } from "react";
import { APIRequest1 } from "../../utils/api_utils";
import "./pointTransfer.css";
import { jwtDecode } from "jwt-decode";
import { useSnackbar } from "notistack";
import ConfettiExplosion from "react-confetti-explosion";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useAuth } from "../../auth/authContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { addDataToProgramDataStore } from "../../data-store/program-data-store";
import { getDataFromProgramDataStore } from "../../data-store/program-data-store";
import { getDataFromTransferPartnerDataStore } from "../../data-store/transfer-partner-data-store";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
};

// interface ProgramPoints {
//   programName: string;
//   programCode: string;
//   programId: number;
//   toSidePoints: number;
//   // Add other properties as needed
// }

export const PointsTransfer = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  let localData = localStorage.getItem("verifySession");
  let parsedData = null;

  parsedData = JSON.parse(localData);

  // console.log(parsedData, "localData");
  const baseUrl = process.env.REACT_APP_CORE_ENGINE_URL;

  const PartnerPoints = 1000;
  // token = localStorage.getItem("token");

  // const storedData = JSON.parse(localStorage.getItem('userInfo'));

  let decoded = {};
  if (localStorage.getItem("token")) {
    decoded = jwtDecode(localStorage.getItem("token"));
  }
  // const decoded = jwtDecode(localStorage.getItem("token"));
  // console.log(decoded, "decoded====");

  const [points, setPoints] = useState(
    JSON.parse(localStorage.getItem("userPoints"))
      ? Math.round(JSON.parse(localStorage.getItem("userPoints")) / 2)
      : 0
  );
  // const [points, setPoints] = useState(
  //   Math.round(JSON.parse(localStorage.getItem("userPoints")) / 2)
  // );

  // const [points, setPoints] = useState(decoded?.pointBalance / 2);

  const [calculatedPoints, setCalculatedPoints] = useState(0);
  const [partnerMemberId, setPartnerMemberId] = useState("1001");
  const [memberId, setmemberId] = useState("");
  const [sideMenu, setSideMenu] = useState(false);
  const [allPrograms, setAllPrograms] = useState([]);

  const [allTransferPartner, setAllTransferPartner] = useState([]);
  const [programPoints, setProgramPoints] = useState([]);
  const [programPoints1, setProgramPoints1] = useState([]);
  const [selectedToSideProgram, setSelectedToSideProgram] = useState(null);
  // const [individualProgramPoints, setIndividualProgramPoints] =
  //   useState<ProgramPoints | null>(null);
  const [isSubmittedSuccessfull, setIsSubmittedSuccessfull] = useState(false);
  const [open, setOpen] = useState(false);
  const [onSubmitLoading, setOnSubmitLoading] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState(points);
  const [calculateLoading, setCalculateLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const debounceDelay = 300;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    // setIsSubmittedSuccessfull(false);
    setmemberId("");
    setOnSubmitLoading(false);
  };

  const handleSubmit = async () => {
    setOnSubmitLoading(true);
    try {
      if (selectedToSideProgram?.toSidePoints > 0) {
        const response = await APIRequest1(
          "post",
          baseUrl,
          "/transfer/doTransferPoints",

          {
            fromMemberId: JSON.parse(localStorage.getItem("userInfo"))
              ?.memberId,
            transferPartnerCode: JSON.parse(
              localStorage.getItem("transferPartner")
            ).partnerCode,
            toMemberId: memberId,
            programCode: selectedToSideProgram?.programCode,
            fromSidePoints: points,
            toSidePoints: selectedToSideProgram?.toSidePoints,
          }
        );

        enqueueSnackbar("Submitted successfully", {
          variant: "success",
          autoHideDuration: 1700,
        });
        setIsSubmittedSuccessfull(true);
        handleClose();
        setOnSubmitLoading(false);

        if (response.data.transferPartnerMemberBalance) {
          localStorage.setItem(
            "userPoints",
            response.data.transferPartnerMemberBalance
          );
        }

        // setPoints(JSON.parse(localStorage.getItem("userPoints")));

        if (localStorage.getItem("userPoints")) {
          setPoints(JSON.parse(localStorage.getItem("userPoints")));
        }
      }
    } catch (error) {
      // console.log(error);
      enqueueSnackbar(error?.response?.data?.errorMessage, {
        variant: "error",
      });
      setOnSubmitLoading(false);
    }
  };

  //   {
  //     "programCode": "LOYALTY123",
  //     "programKey": "SECRET_KEY_456",
  //     "memberId": "USER789",
  //     "memberFirstName": "John",
  //     "memberLastName": "Doe",
  //     "pointBalance": 1000
  // }

  // let localData = localStorage.getItem("verifySession");

  // let parsedData = JSON.parse(localData);

  // console.log(parsedData.pointBalance, "localData");

  // const handleCalculate = () => {
  //   try {
  //     APIRequest1("post", baseUrl, "/transfer/calculateToSidePoints", {
  //       transferPartnerCode: partner.value,
  //       fromSidePoints: points,
  //       // fromSidePoints: points,
  //       // programCodes: [program.value],
  //       programCodes: [...allPrograms],
  //     }).then((response) => {
  //       if (response.data) {
  //         setProgramPoints(response?.data?.toSidePointsList);
  //       }
  //     });
  //   } catch (error) {
  //     enqueueSnackbar(error, { variant: "error" });
  //   }
  // };

  const handleCalculate = async () => {
    setCalculateLoading(true);
    try {
      const response = await APIRequest1(
        "post",
        baseUrl,
        "/transfer/calculateToSidePoints",
        {
          transferPartnerCode: JSON.parse(
            localStorage.getItem("transferPartner")
          ).partnerCode,
          // transferPartnerCode: partner.value,
          fromSidePoints: points ? points : 0,
          // fromSidePoints: points,
          // programCodes: [program.value],
          // programCodes: [...allTransferPartner],
          programCodes: [...allPrograms],
        }
      );

      if (response.data) {
        setCalculateLoading(false);
        setProgramPoints(response?.data?.toSidePointsList);
      }
    } catch (error) {
      setCalculateLoading(false);
      enqueueSnackbar(error, { variant: "error" });
    }
  };

  useEffect(() => {
    if (allPrograms?.length > 0) {
      handleCalculate(points);
    }
  }, [allPrograms]);

  const getAllPrograms = async () => {
    try {
      const response = await APIRequest1(
        "get",
        baseUrl,
        "/program/getAllPrograms"
      );
      // (response) => {
      //   if (response.status === 403) {
      //     throw new Error(
      //       "Forbidden: You don't have access to this resource."
      //     );
      //   }
      if (response.data && response.data.length > 0) {
        let structuredData = [];
        let temp = response.data.map((item) =>
          structuredData.push(item?.programCode)
        );
        // console.log(structuredData, 'structuredData=====');
        if (structuredData.length > 0) {
          setAllPrograms(structuredData);
        }
      }
      // console.log(response);
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
    }
  };

  // useEffect(() => {
  //   getTransferPartner();
  // }, []);

  const getTransferPartner = async () => {
    try {
      const response = await APIRequest1(
        "post",
        baseUrl,
        "/transferPartner/getTransferPartnersFromDB",
        {}
      );
      let structuredData = [];
      let temp = response.data.transferPartnerVOList.map((item) => {
        return structuredData.push(item.partnerCode);
      });
      // console.log(structuredData, "structuredData");
      // console.log(response?.data?.transferPartnerVOList, "response");
      if (structuredData.length > 0) {
        setAllTransferPartner(structuredData);
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    getProgramsAssignedToTransferPartner();
  }, []);

  // -----------------------------------------

  // console.log(
  //   JSON.parse(localStorage.getItem("transferPartner")).partnerCode,
  //   "JSON.parse(localStorage.getItem"
  // );

  const getProgramsAssignedToTransferPartner = async () => {
    try {
      let config = {
        method: "get",
        url: `${baseUrl}/transferPartner/getProgramsAssignedToTransferPartner`,
        // headers: {
        //   Authorisation: `Bearer ${localStorage.getItem('accessToken')}`,
        // },
        params: {
          transferPartnerId: JSON.parse(localStorage.getItem("transferPartner"))
            .id,
        },
      };

      const storedData = JSON.parse(localStorage.getItem("userInfo"));

      const response = await axios(config);

      if (response.data && response.data.length > 0) {
        let structuredData = [];
        let temp = response.data.map((item) => {
          addDataToProgramDataStore(item?.programCode, item);
          return structuredData.push(item?.programCode);
        });
        // console.log(structuredData, 'structuredData=====');
        if (structuredData.length > 0) {
          setAllPrograms(structuredData);
        }
      }
      // console.log(response, "response");
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
    }
  };

  // --------------------------------------

  // useEffect(() => {
  //   getAllPrograms();
  // }, []);

  useEffect(() => {
    if (isSubmittedSuccessfull) {
      const timer = setTimeout(() => {
        setIsSubmittedSuccessfull(false); // Hide the confetti after 3 seconds
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isSubmittedSuccessfull]);

  const handlePointInputChange = (e) => {
    // setPoints(e?.target?.value);
    let value = e.target.value;
    let maxPoints = JSON.parse(localStorage.getItem("userPoints"));
    let minPoints = 0;

    if (value === "") {
      setPoints("");
      setDebouncedValue("");
    } else if (!isNaN(value)) {
      value = Number(value);
      if (value >= minPoints && value <= maxPoints) {
        setPoints(value);
        setDebouncedValue(value);
      } else if (value < minPoints) {
        setPoints(minPoints);
        setDebouncedValue(minPoints);
      } else if (value > maxPoints) {
        setPoints(maxPoints);
        setDebouncedValue(maxPoints);
      }
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      handleCalculate();
    }, debounceDelay);

    return () => {
      clearTimeout(handler);
    };
  }, [points]);

  // console.log(
  //   JSON.parse(localStorage.getItem("transferPartner")).partnerLogoUri,
  //   "JSON.parse(localStorage.getItem"
  // );
  return (
    <Box
      className="mainBox"
      // style={{ display: "flex", padding: "24px" }}
    >
      {/* <Box style={{ width: "20%" }}>
        <SideNavBar />
      </Box> */}

      <Box
        style={{
          // border: "1px solid black",
          width: "100%",
          margin: "0px 2px",
          padding: "20px 0px",
          // padding: "20px 70px",
        }}
      >
        <Box style={{ height: "100vh", position: "relative" }}>
          <Box
            sx={{
              //   position: 'absolute',
              width: "100%",
              //   height: '70%',
              backgroundColor: "white",
              borderRadius: "30px 30px 0px 0px",
            }}
          >
            <Box
              className="marginBox"
              // style={{ margin: "0px 32px" }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Box
                  className="pointExchangeBox"
                  // style={{
                  //   width: "100%",
                  //   display: "flex",
                  //   flexDirection: "column",
                  //   alignItems: "center",
                  //   margin: "25px 25px 25px 25px",
                  //   borderRadius: "30px",
                  //   // border: "1px solid",
                  // }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      marginBottom: "30px",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      style={{
                        width: "80px",
                        height: "80px",
                      }}
                    >
                      <img
                        src={
                          `${baseUrl}` +
                          `${
                            JSON.parse(localStorage.getItem("transferPartner"))
                              ?.partnerLogoUri
                          }`
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                      {/* <img
                        src={
                          `${baseUrl}` +
                          `${
                            getDataFromTransferPartnerDataStore(
                              getDataFromTransferPartnerDataStore(
                                "SELECTED_TRANSFER_PARTNER_CODE"
                              )
                            )?.partnerLogoUri
                          }`
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      /> */}
                    </Box>
                    <Box
                      onClick={() => {
                        navigate("/");
                        logout();
                      }}
                      style={{ display: "flex", cursor: "pointer" }}
                    >
                      <Typography
                        style={{ fontWeight: "bold", marginRight: "15px" }}
                      >
                        Logout
                      </Typography>
                      <ExitToAppIcon />
                    </Box>
                  </Box>

                  <Box style={{ width: "100%" }}>
                    <Typography style={{ fontSize: "20px" }} variant="h6">
                      Point Exchange
                    </Typography>
                    <Typography
                      style={{ fontSize: "14px", marginTop: "10px" }}
                      variant="h6"
                    >
                      Exchange Details
                    </Typography>
                  </Box>

                  {/* --------------- */}
                  <Box
                    style={{
                      width: "100%",
                      backgroundColor: "#F1F4FA",
                      //   height: '500px',
                      marginTop: "30px",
                      borderRadius: "10px",
                      padding: "30px",
                    }}
                  >
                    {/* member id name  */}
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <Typography
                          id="memberIdTypo"
                          // style={{ fontWeight: "bold" }}
                        >
                          Member Id
                        </Typography>
                        <Typography
                          style={{ fontSize: "14px", marginTop: "7px" }}
                        >
                          {/* {parsedData
                            ? parsedData?.memberId
                            : decoded?.memberId} */}

                          {
                            JSON.parse(localStorage.getItem("userInfo"))
                              ?.memberId
                          }
                          {/* {partnerMemberId} */}
                        </Typography>
                        <Box>
                          {isSubmittedSuccessfull && (
                            <ConfettiExplosion
                              floorHeight={800}
                              floorWidth={1600}
                              duration={2000}
                            />
                          )}
                        </Box>
                      </Box>
                      <Box style={{ textAlign: "center" }}>
                        <Typography
                          id="memberIdTypo"
                          // style={{ fontWeight: "bold" }}
                        >
                          Member Name
                        </Typography>
                        <Typography
                          style={{ fontSize: "14px", marginTop: "7px" }}
                        >
                          {/* {partner.label} */}
                          {/* {decoded?.firstName} */}
                          {/* {parsedData
                            ? parsedData?.memberFirstName
                            : decoded?.firstName} */}

                          {`${
                            JSON.parse(localStorage.getItem("userInfo"))
                              ?.firstName
                          }` +
                            " " +
                            `${
                              JSON.parse(localStorage.getItem("userInfo"))
                                ?.lastName
                            }`}
                        </Typography>
                        <Box>
                          {isSubmittedSuccessfull && (
                            <ConfettiExplosion
                              floorHeight={800}
                              floorWidth={1600}
                              duration={2000}
                            />
                          )}
                        </Box>
                      </Box>
                      <Box style={{ textAlign: "right" }}>
                        <Typography
                          id="memberIdTypo"
                          // style={{ fontWeight: "bold" }}
                        >
                          Total Points
                        </Typography>
                        <Typography
                          style={{ fontSize: "14px", marginTop: "7px" }}
                        >
                          {JSON.parse(localStorage.getItem("userPoints"))}
                        </Typography>
                        <Box>
                          {isSubmittedSuccessfull && (
                            <ConfettiExplosion
                              floorHeight={800}
                              floorWidth={1600}
                              duration={2000}
                            />
                          )}
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      style={{ borderTop: "1px solid grey", marginTop: "6px" }}
                    ></Box>

                    {/* amount of point exchange */}

                    <Box>
                      <Box style={{ marginTop: "30px" }}>
                        <Typography
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          Amount of points to Exchange
                        </Typography>
                      </Box>

                      <Box
                        className="sliderMainBox"
                        // style={{
                        //   display: "flex",
                        //   alignItems: "center",
                        //   justifyContent: "space-between",
                        //   width: "100%",
                        // }}
                      >
                        <Box
                          className="sliderTextfieldBox"
                          // style={{
                          //   // border: "1px solid",
                          //   width: "80%",
                          //   display: "flex",
                          //   alignItems: "center",
                          //   justifyContent: "space-between",
                          // }}
                        >
                          <Box
                            style={{
                              width: "70%",
                              padding: "10px",
                              backgroundColor: "white",
                              height: "60px",
                              display: "flex",
                              // alignItems: 'center',
                              borderRadius: "10px",
                              marginTop: "10px",
                              position: "relative",
                              // border: "1px solid",
                            }}
                          >
                            <Slider
                              value={points}
                              defaultValue={points}
                              min={0}
                              max={JSON.parse(
                                localStorage.getItem("userPoints")
                              )}
                              // max={decoded?.pointBalance}
                              // max={1000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              onChange={(e) => setPoints(e.target.value)}
                              style={{ color: "black" }}
                              onChangeCommitted={handleCalculate}
                            />

                            <Box
                              style={{
                                position: "absolute",
                                bottom: "3px",
                                left: "5px",
                                backgroundColor: "#F6E5E5",
                                borderRadius: "12px",
                                padding: "2px 10px",
                              }}
                            >
                              <Typography
                                style={{ fontSize: "11px", fontWeight: "bold" }}
                              >
                                0
                              </Typography>
                            </Box>
                            <Box
                              style={{
                                position: "absolute",
                                bottom: "3px",
                                right: "5px",
                                backgroundColor: "#F6E5E5",
                                borderRadius: "12px",
                                padding: "2px 10px",
                              }}
                            >
                              <Typography
                                style={{ fontSize: "11px", fontWeight: "bold" }}
                              >
                                {JSON.parse(localStorage.getItem("userPoints"))}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            style={{
                              width: "25%",
                              marginLeft: "10px",
                              marginTop: "6px",
                            }}
                          >
                            <TextField
                              value={points}
                              type="number"
                              // inputProps={{
                              //   max: parsedData
                              //     ? parsedData?.pointBalance
                              //     : decoded?.pointBalance,
                              // }}
                              sx={{
                                "& .MuiInputBase-input": {
                                  padding: "12px 5px", // Adjust these values as needed
                                },
                              }}
                              style={{
                                backgroundColor: "white",
                                borderRadius: "10px",
                                //   padding: '6px 6px 6px 6px',
                                padding: "0px",
                              }}
                              onChange={(e) => {
                                handlePointInputChange(e);
                              }}

                              // onChange={(e) => setPoints(e?.target?.value)}
                            />
                          </Box>
                        </Box>

                        {/* ------------------------------------------ */}

                        <Box
                          className="calculateButtonBox"
                          // style={{ marginLeft: "10px", width: "20%" }}
                        >
                          <Button
                            fullWidth
                            onClick={handleCalculate}
                            style={{
                              height: "52px",
                              marginTop: "4px",
                              textTransform: "none",
                              backgroundColor: "#787A7D",
                            }}
                            variant="contained"
                          >
                            Calculate
                          </Button>
                        </Box>
                      </Box>
                    </Box>

                    {/* Into which Loyalty Currency do you want to exchange? */}

                    <Box>
                      <Box style={{ marginTop: "30px" }}>
                        <Typography
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          Into which Loyalty Currency do you want to exchange?
                        </Typography>
                      </Box>

                      <Box className="transferMainBox">
                        {programPoints.length === 0 && (
                          <Box
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {/* <CircularProgress style={{ color: "black" }} /> */}
                            <Typography
                              style={{ fontSize: "18px", fontWeight: "bold" }}
                            >
                              No Programs found !
                            </Typography>
                          </Box>
                        )}
                        {programPoints.map((item, index) => {
                          let programCode = item["programCode"];
                          let programLogoUrl =
                            baseUrl +
                            getDataFromProgramDataStore(programCode)[
                              "programLogoUri"
                            ];

                          return (
                            <Box
                              className="transferSelectBox"
                              key={item?.programId}
                            >
                              <Box
                                style={{
                                  width: "60px",
                                  height: "60px",
                                  //   border: '1px solid',
                                  margin: "5px 0px",
                                }}
                              >
                                <img
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                  src={programLogoUrl}
                                />
                              </Box>

                              <Box
                                style={{
                                  textAlign: "center",
                                  margin: "5px 0px",
                                }}
                              >
                                <Typography style={{ fontSize: "12px" }}>
                                  {item?.programName}
                                </Typography>
                              </Box>

                              <Box style={{ margin: "5px 0px" }}>
                                <Typography
                                  id="sidePointsTypo"
                                  // style={{
                                  //   fontWeight: "bold",
                                  //   fontSize: "22px",
                                  //   color: "gray",
                                  // }}
                                >
                                  {item?.toSidePoints}
                                </Typography>
                              </Box>

                              <Box>
                                <Button
                                  disabled={calculateLoading}
                                  variant="contained"
                                  onClick={() => {
                                    setIsSubmittedSuccessfull(false);
                                    handleOpen();
                                    setSelectedToSideProgram(item);
                                  }}
                                  style={{
                                    fontSize: "12px",
                                    textTransform: "none",
                                    backgroundColor: "#E0E8EF",
                                    color: "black",
                                  }}
                                >
                                  Choose
                                </Button>
                              </Box>
                            </Box>
                          );
                        })}

                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box className="modalMainBox">
                            <Box
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box>
                                <Typography style={{ fontWeight: "bold" }}>
                                  From
                                </Typography>
                              </Box>
                              <Box>
                                <Typography style={{ fontWeight: "bold" }}>
                                  Into
                                </Typography>
                              </Box>
                            </Box>
                            {/* border */}
                            <Box
                              style={{
                                borderTop: "1px solid gray",
                                marginTop: "10px",
                              }}
                            ></Box>

                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "30px",
                              }}
                            >
                              <Box style={{ width: "60px", height: "60px" }}>
                                <img
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                  src={
                                    `${baseUrl}` +
                                    `${
                                      JSON.parse(
                                        localStorage.getItem("transferPartner")
                                      )?.partnerLogoUri
                                    }`
                                  }
                                />
                                {/* <img
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                  src={
                                    `${baseUrl}` +
                                    `${
                                      getDataFromTransferPartnerDataStore(
                                        getDataFromTransferPartnerDataStore(
                                          "SELECTED_TRANSFER_PARTNER_CODE"
                                        )
                                      )?.partnerLogoUri
                                    }`
                                  }
                                /> */}
                              </Box>
                              <Box style={{ width: "40%" }}>
                                <Box style={{ textAlign: "center" }}>
                                  <Typography
                                    style={{ color: "gray", fontSize: "14px" }}
                                  >
                                    Exchange Into
                                  </Typography>
                                  <div className="arrow-container">
                                    <div className="arrow">
                                      <div className="arrow-head"></div>
                                    </div>
                                  </div>
                                </Box>
                              </Box>
                              <Box style={{ width: "60px", height: "60px" }}>
                                {selectedToSideProgram && (
                                  <img
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "contain",
                                    }}
                                    src={
                                      `${baseUrl}` +
                                      `${
                                        getDataFromProgramDataStore(
                                          selectedToSideProgram.programCode
                                        )["programLogoUri"]
                                      }`
                                    }
                                  />
                                )}
                              </Box>
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "10px",
                              }}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "flex-end",
                                }}
                              >
                                <Typography style={{ fontWeight: "bold" }}>
                                  {points}
                                </Typography>
                                {/* <Typography style={{ fontWeight: 'bold' }}>{points}</Typography> */}
                                <Typography
                                  style={{
                                    fontSize: "11px",
                                    marginLeft: "4px",
                                    marginBottom: "3px",
                                  }}
                                >
                                  Points
                                </Typography>
                              </Box>

                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "flex-end",
                                }}
                              >
                                <Typography style={{ fontWeight: "bold" }}>
                                  {selectedToSideProgram?.toSidePoints}
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: "11px",
                                    marginLeft: "4px",
                                    marginBottom: "3px",
                                  }}
                                >
                                  Points
                                </Typography>
                              </Box>
                            </Box>

                            {/* enter memebership id */}
                            <Box>
                              <Box style={{ marginTop: "15px" }}>
                                <Typography
                                  id="modalMembershipIdTypo"
                                  // style={{ fontWeight: "bold" }}
                                >
                                  {`Enter your ${selectedToSideProgram?.programName} membership Id*`}
                                </Typography>
                              </Box>
                              <Box style={{ width: "100%", marginTop: "7px" }}>
                                <TextField
                                  value={memberId}
                                  onChange={(e) => setmemberId(e.target.value)}
                                  style={{ padding: "0px" }}
                                  fullWidth
                                />
                              </Box>
                            </Box>

                            {/* buttons */}
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Box style={{ width: "48%" }}>
                                <Button
                                  fullWidth
                                  onClick={handleClose}
                                  style={{
                                    padding: "10px 50px",
                                    border: "1px solid #E5E5E5",
                                    textTransform: "none",
                                    backgroundColor: "#E5E5E5",
                                    color: "black",
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Box>
                              <Box style={{ width: "48%" }}>
                                <Button
                                  disabled={memberId === ""}
                                  fullWidth
                                  onClick={handleSubmit}
                                  variant="contained"
                                  style={{
                                    padding: "10px 50px",
                                    textTransform: "none",
                                    // backgroundColor: "black",
                                    backgroundColor:
                                      memberId === ""
                                        ? "rgb(229, 229, 229)"
                                        : "black",
                                    opacity: memberId === "" ? 0.5 : 1,
                                  }}
                                >
                                  {onSubmitLoading ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    "Submit"
                                  )}
                                  {/* Submit */}
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        </Modal>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
