import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/authContext";
import LoginImg from "../../assets/images/loginImage.png";
import newLoginImg from "../../assets/images/newLoginImg.png";
import newLoginImg1 from "../../assets/images/newLoginImg1.png";
import newLoginImg3 from "../../assets/images/newLoginImg3.png";
// import newLoginImg2 from "../../assets/images/newLoginImg2.png";
import loyyalLogo from "../../assets/images/loyyalLogo.svg";
import { APIRequest1 } from "../../utils/api_utils";
import { useSnackbar } from "notistack";
import "./login.css";
import { addDataToTransferPartnerDataStore } from "../../data-store/transfer-partner-data-store";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const Login = () => {
  // const query = new URLSearchParams(useLocation().search);
  // const query = useQuery();
  // const token = query.get("sessionToken");
  // console.log(token, "token");

  const query = useQuery();
  const token = query.get("sessionToken");
  // console.log(token, "token");

  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_CORE_ENGINE_URL;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [allProgram, setAllProgram] = useState([]);
  const [allTransferPartner, setAllTransferPartner] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState("");
  const [verifySession, setVerifySession] = useState(null);
  const [loginLoading, setLoginLoading] = useState(false);

  const [selectedTranferPartnerId, setSelectedTransferPartnerId] =
    useState(null);

  useEffect(() => {
    if (token) {
      localStorage.setItem(
        "sessionTemp",
        // "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MTk1NTcxMjAsImlzcyI6ImNvcmUtZW5naW5lLXNlcnZpY2UiLCJzdWIiOiJqb2huQGdtYWlsLmNvbSIsImF1ZCI6WyJjb3JlLWVuZ2luZS1zZXJ2aWNlIiwiY29udHJhY3QtbWFuYWdlbWVudC1zZXJ2aWNlIiwiYWxsLWxveXlhbC1mcm9udGVuZHMiXSwiYXV0aG9yaXplZCI6dHJ1ZSwiZmlyc3ROYW1lIjoiSm9obiIsImxhc3ROYW1lIjoiSm9obiIsImVtYWlsIjoiam9obkBnbWFpbC5jb20iLCJtZW1iZXJJZCI6IjEyMzQ1IiwicHJvZ3JhbUNvZGUiOiJlbm9jIiwicG9pbnRCYWxhbmNlIjoiMTAwMDAwIiwicm9sZUNvZGUiOiJQUk9HUkFNX0FETUlOIiwiZXhwIjoxNzUxMDkzMTIwfQ.aLJtmuMeHUmiefm6x2_WPTUrJ-Nj6eQjnWQ9NUO0Lsbb_TBadVQ-_LUnoWM3zRLsQjHg6zxlGrsTHAN8PtZlBQnE-OBwDJ9DqrdXQJSV3PpT7A2qxmwdw_mvviDZjlqXIukpWI0XPnWDvRpb0x3P_ox45wcNhrTEbggM91gmQORuGtvbIqW_G1sSbtyuurnbWI2kdPdfmuZ4yuZqMKJn6EbFAlGZHtN-BkQxaIcmUtLcEOEJk4f_Cyp6KemBQQNf5EfGuD7E0PYwAHWE5aKw-yRCVU_9LqdmgLa6YJHORbFPUajO52uc95w2awl6CIFY6sR0DgvQAZWZzxq17Ra6nA"
        token
      );
      // localStorage.setItem(
      //   "token",
      //   token
      // );
    }

    // if (localStorage.getItem("token")) {
    //   navigate("/points-transfer");
    // }
  }, [token]);

  console.log(selectedProgram, "selectedProgram-----");

  useEffect(() => {
    if (localStorage.getItem("sessionTemp") && verifySession) {
      navigate("/points-transfer");
    }
    // localStorage.setItem("verifySession", verifySession);
  }, [verifySession]);

  useEffect(() => {
    verifySessionToken();
  }, []);

  const verifySessionToken = async () => {
    try {
      const response = await APIRequest1(
        "get",
        baseUrl,
        `/widget/verifySessionToken?sessionToken=${token}`
        // "/widget/verifySessionToken?sessionToken=3cdbc00b-5d65-434e-bfdc-c541c87478bc"
      );

      // if (response.data && response.data.length > 0) {
      //   let structuredData = [];
      //   let temp = response.data.map((item) =>
      //     structuredData.push(item?.programCode)
      //   );
      //   // console.log(structuredData, 'structuredData=====');
      //   if (structuredData.length > 0) {
      //     setAllPrograms(structuredData);
      //   }
      // }

      // console.log(response);   JSON.stringify
      setVerifySession(response?.data);
      localStorage.setItem("verifySession", JSON.stringify(response?.data));
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
    }
  };

  // console.log(verifySession, "verifySession-----");

  const tempAllPrograms = [
    {
      id: 1,
      programName: "ENOC",
      programCode: "enoc",
    },
    {
      id: 2,
      programName: "Al Seef",
      programCode: "alseef",
    },
    {
      id: 3,
      programName: "Al Seef1",
      programCode: "alseef1",
    },
    {
      id: 4,
      programName: "Al Seef2",
      programCode: "alseef2",
    },
    {
      id: 5,
      programName: "Al Seef3",
      programCode: "alseef3",
    },
    // {
    //   id: 5,
    //   programName: "loyalty 1",
    //   programCode: "plidf1",
    // },
  ];

  const { enqueueSnackbar } = useSnackbar();

  const { login } = useAuth();

  //   console.log(useAuth, "useAuth");

  useEffect(() => {
    // getAllProgram();
    getTransferPartner();
  }, []);

  const handleLogin = async () => {
    // enqueueSnackbar("This is a success message!", {
    //   variant: "success",
    // });

    // let lowerCaseProgram = "enoc";
    let lowerCaseProgram = selectedProgram;
    // let lowerCaseProgram = selectedProgram.toLowerCase();

    if (email && password && selectedProgram) {
      setLoginLoading(true);
      const success = await login(email, password, lowerCaseProgram);
      // console.log(success, "success00000000000000000000000");
      if (success) {
        setLoginLoading(false);
        navigate("/points-transfer");
      } else {
        setLoginLoading(false);
        alert("Invalid Member ID or Password");
      }
    } else if (!email) {
      enqueueSnackbar("Please enter email", {
        variant: "error",
        autoHideDuration: 1000,
      });
    } else if (!password) {
      enqueueSnackbar("Please enter password", {
        variant: "error",
        autoHideDuration: 1000,
      });
    } else if (!selectedProgram) {
      enqueueSnackbar("Please select Transfer Partner", {
        variant: "error",
        autoHideDuration: 1000,
      });
    }
  };

  // const getTransferPartner1 = async () => {
  //   await APIRequest1(
  //     'get',
  //     baseUrl,
  //     `/transferPartner/getTransferPartnersFromDB`,
  //     {},
  //   )
  //     .then((response) => console.log(response))
  //     .catch((error) => HandleError(error));
  // };

  const getTransferPartner = async () => {
    try {
      const response = await APIRequest1(
        "post",
        baseUrl,
        "/transferPartner/getTransferPartnersFromDB",
        {}
      );

      console.log(response?.data?.transferPartnerVOList, "response");

      response?.data?.transferPartnerVOList.forEach((partner) => {
        addDataToTransferPartnerDataStore(partner.partnerCode, partner);
      });
      setAllTransferPartner(response?.data?.transferPartnerVOList);
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
    }
  };

  const getAllProgram = async () => {
    try {
      const response = await APIRequest1(
        "GET",
        baseUrl,
        "/program/getAllPrograms"
      );

      console.log(response, "program response");
      // enqueueSnackbar("success", { variant: "error" });
      setAllProgram(response?.data);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
      });
    }
  };

  const onProgramSelect = (e) => {
    addDataToTransferPartnerDataStore(
      "SELECTED_TRANSFER_PARTNER_CODE",
      e.target.value
    );
    setSelectedProgram(e.target.value);
  };

  const handleSaveSelectedItem = (selectedCode) => {
    const selectedItem = allTransferPartner.find(
      (item) => item.partnerCode === selectedCode
    );

    // localStorage.setItem("userInfo", JSON.stringify(response.data));

    localStorage.setItem("transferPartner", JSON.stringify(selectedItem));

    console.log(selectedItem, "selectedItem");

    // setSelectedTransferPartnerId
    // setSelectedMerchant(selectedItem);

    // console.log('Selected Item:', selectedItem);
  };

  // console.log(allProgram, "allProgram------");
  // console.log(selectedProgram, "selectedProgram------");

  // console.log(email, password);

  return (
    <Box>
      <Box
        style={{
          // border: "1px solid ",
          display: "flex",
          height: "100vh",
          width: "100%",
        }}
      >
        <Box className="imageBox" style={{ padding: "1% 1%" }}>
          <Box
            style={{
              flex: 1,
              height: "100%",
            }}
          >
            <img
              src={newLoginImg3}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                borderRadius: "10px",
              }}
            />
          </Box>
        </Box>
        <Box
          className="loginMainBox"
          // style={{
          //   width: "40%",
          //   display: "flex",
          //   // justifyContent: "center",
          //   alignItems: "center",
          // }}
        >
          <Box
            className="loginChildBox"
            // style={{
            //   padding: "80px 10%",

            //   display: "flex",

            //   width: "60%",
            // }}
          >
            <Box style={{ width: "100%" }}>
              {/* image box */}
              <Box style={{ width: "100px", height: "50px" }}></Box>
              <Box style={{ marginTop: "20px" }}>
                <Box
                  style={{
                    width: "100px",
                    height: "40px",
                    // border: "1px solid",
                    marginBottom: "20px",
                  }}
                >
                  <img
                    src={loyyalLogo}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Box>
                <Typography style={{ fontSize: "35px", fontWeight: "bold" }}>
                  Hello, Welcome
                </Typography>
                {/* <Typography>Please enter you details to login</Typography> */}
              </Box>

              {/* email password program */}
              <Box style={{ marginTop: "20px" }}>
                <Box>
                  <Typography style={{ fontSize: "20px" }}>
                    Member Id
                  </Typography>
                  <TextField
                    value={email}
                    type="email"
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "12px 20px",
                        border: "1px solid #787A7D",
                        borderRadius: "7px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #787A7D",
                        borderRadius: "7px",
                      },
                    }}
                    fullWidth
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </Box>

                <Box style={{ marginTop: "15px" }}>
                  <Typography style={{ fontSize: "20px" }}>Password</Typography>
                  <TextField
                    value={password}
                    type="password"
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "12px 20px",
                        border: "1px solid #787A7D",
                        borderRadius: "7px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #787A7D",
                        borderRadius: "7px",
                      },
                    }}
                    fullWidth
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </Box>
                {/* select */}
                <Box style={{ position: "relative", marginTop: "15px" }}>
                  <Typography style={{ fontSize: "20px" }}>
                    Select Transfer Partner
                  </Typography>
                  <Select
                    value={selectedProgram}
                    fullWidth
                    sx={{
                      // width: "200px",
                      "& .MuiSelect-select": {
                        padding: "12px 20px",
                      },
                      borderRadius: "7px",
                      border: "1px solid #787A7D",
                    }}
                    // InputProps={{
                    //   sx: { borderRadius: '7px', border: '1px solid #E0E0E0' },
                    // }}

                    onChange={(e) => {
                      onProgramSelect(e);
                      handleSaveSelectedItem(e.target.value);
                    }}
                  >
                    {allTransferPartner?.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item?.partnerCode}>
                          {item?.partnerName}
                        </MenuItem>
                      );
                    })}
                    {/* {tempAllPrograms?.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item?.programCode}>
                          {item?.programName}
                        </MenuItem>
                      );
                    })} */}
                  </Select>
                  {!selectedProgram && (
                    <Typography
                      style={{
                        position: "absolute",
                        left: "20px",
                        top: "54%",
                        color: "gray",
                        cursor: "pointer",
                      }}
                    >
                      Select
                    </Typography>
                  )}
                </Box>

                <Button
                  // disabled={!!email && !!password ? true : false}
                  onClick={handleLogin}
                  fullWidth
                  variant="contained"
                  style={{
                    marginTop: "30px",
                    textTransform: "none",
                    backgroundColor: "black",
                  }}
                >
                  {loginLoading ? <CircularProgress size={20} /> : "Login"}
                  {/* Login */}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
